import { useState, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import Dropzone from "react-dropzone";
import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { formatBytes, normalizePayloadToFormData } from "../../utils/form";

export const ImageUploadField = ({
  control,
  name = "thumbnail",
  updateAction,
  id,
  errorMessage = "",
  onUpload,
  onDelete,
  defaultValues,
}) => {
  const {
    fields: thumbnail,
    append: appendThumbnailImage,
    remove: removeThumbnailImage,
    replace: replaceThumbnailImage,
  } = useFieldArray({
    control,
    name,
  });

  useEffect(() => {
    // Ensure the field gets populated when defaultValues are available
    if (defaultValues?.image) {
      replaceThumbnailImage(defaultValues.image);
    }
  }, [defaultValues?.image, replaceThumbnailImage]);

  const handleFileUpload = async (file) => {
    const fileWithPreview = {
      ...file,
      preview: URL.createObjectURL(file),
    };

    replaceThumbnailImage([fileWithPreview]);

    if (updateAction) {
      const formData = normalizePayloadToFormData({
        thumbnail: file,
      });
      await onUpload(formData, id);
    }
  };

  const handleFileRemove = async () => {
    if (thumbnail[0]?.link) {
      await onDelete(id, { imageName: thumbnail[0].name });
    }

    removeThumbnailImage(0);
  };

  return (
    <div>
      <Dropzone
        onDrop={(acceptedFiles) => {
          const uploadedFile = acceptedFiles[0];
          handleFileUpload(uploadedFile);
        }}
        multiple={false}
        accept={{
          "image/*": [
            ".png",
            ".gif",
            ".jpeg",
            ".jpg",
            ".webp",
            ".bmp",
            ".tiff",
          ],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="flex flex-col items-center px-4 py-12 text-center bg-white border border-gray-300 border-dashed rounded-md cursor-pointer hover:border-gray-400">
                <span className="mb-4 text-3xl">
                  <PhotoIcon height={24} />
                </span>
                <span className="text-sm font-light">
                  Upload a file or drag and drop
                </span>
              </p>
            </div>
            {errorMessage && (
              <p className="mt-2 text-xs text-red-500">{errorMessage}</p>
            )}
          </section>
        )}
      </Dropzone>

      <ul className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
        {thumbnail.map((file, index) => (
          <li key={file.id || index} className="relative group">
            <img
              src={file.preview || file.link}
              alt={file?.name || "Uploaded file"}
              className="w-full h-32 object-cover rounded-md"
            />
            <button
              className="absolute top-2 right-2 p-1 bg-gray-800 bg-opacity-75 rounded-full text-white hover:bg-opacity-100"
              onClick={handleFileRemove}
              type="button"
            >
              <XMarkIcon height={20} />
            </button>
            <p className="mt-2 text-sm text-center">
              {file?.name}
              {file.size ? ` - ${formatBytes(file.size)}` : ""}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};
