import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ListingForm } from "../../components/listing/Form";
import { normalizeEditListing } from "../../components/listing/utils";
import { getListing, updateListing } from "../../services/api/listing";

export const EditListing = () => {
  const [listing, setListing] = useState([]);
  const { id } = useParams();

  const fetchAndSetListing = async () => {
    const res = await getListing(id);

    if (res.status === 200) {
      const data = res?.data?.data;
      setListing(data);
    }
    return res;
  };

  useEffect(() => {
    fetchAndSetListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListingForm
      defaultValues={normalizeEditListing(listing)}
      onSave={(payload) => updateListing(id, payload)}
      updateListing={true}
    />
  );
};
