import axios from "axios";
import { getToken, getTokenForMultiPart } from "./getToken";
import { normalizePayloadToFormData } from "../../utils/form";

const BASE_URL = process.env.REACT_APP_PUBLIC_API;
const headers = getToken();
const headersForMultiPart = getTokenForMultiPart();

export const getListings = async ({ page = 1, dateFilter = null }) => {
  try {
    const response = await axios.get(`${BASE_URL}/admin/listings/list`, {
      headers,
      params: {
        page,
        dateFilter,
      },
    });
    return response;
  } catch (error) {
    console.error("Error while calling get listings api:", error);
  }
};

export const createListing = async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/admin/listings/create-discovery-site`,
      payload,
      {
        headers,
      }
    );

    if (response.data.data.id) {
      const id = response.data.data.id;
      const thumbnailFormData = normalizePayloadToFormData({
        thumbnail: payload.thumbnail,
      });
      const galleryFormData = normalizePayloadToFormData({
        images: payload.gallery,
      });

      if (payload.thumbnail)
        await uploadListingThumbnail(thumbnailFormData, id);
      if (payload.gallery) await uploadListingGallery(galleryFormData, id);
    }
  } catch (error) {
    console.error("Error while calling create listing api:", error);
  }
};

export const uploadListingThumbnail = async (payload, id) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/admin/listings/upload-thumbnail-image/${id}`,
      payload,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling upload listing thumbnail api:", error);
  }
};

export const uploadListingGallery = async (payload, id) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/admin/listings/upload-gallery-image/${id}`,
      payload,
      { headers: headersForMultiPart }
    );
    return response;
  } catch (error) {
    console.error("Error while calling upload listing thumbnail api:", error);
  }
};

export const deleteListingImage = async (id, payload) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/admin/listings/remove-gallery-image/${id}`,
      payload,
      { headers }
    );
    return response;
  } catch (error) {
    console.error("Error while calling get delete listing image api:", error);
  }
};

export const updateListing = async (id, payload) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/admin/listings/${id}`,
      payload,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    console.error("Error while calling update listing api:", error);
  }
};

export const getListing = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/admin/listings/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error while calling get campsite api:", error);
  }
};

export const deleteListing = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/campsite/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error while calling delete campsite api:", error);
  }
};
