import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { ImageUploadField } from "../ui/ImageUploadField";
import { GalleryUploadField } from "../ui/GalleryUploadField";
import Spinner from "../ui/Spinner";
import GeooLocationSelectorLeaflet from "./GeoLocationSelectorLeaflet";
import { LatLng } from "leaflet";

import {
  activitiesList,
  amenitiesList,
  emptyListingDefaultValues,
  naturalFeaturesList,
  setFormValues,
} from "./utils";
import {
  deleteListingImage,
  uploadListingGallery,
  uploadListingThumbnail,
} from "../../services/api/listing";

export const ListingForm = ({
  defaultValues = emptyListingDefaultValues,
  onSave = () => {},
  updateListing = false,
}) => {
  const navigate = useNavigate();

  const [geoLocation, setGeoLocation] = useState(null); // Initialize as null
  const [isDiscoverySite, setIsDiscoverySite] = useState(true); // Default value is true

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    defaultValues,
  });

  const availableAmenitiesList = ["Wi-Fi", "Parking", "Pool", "Gym"];
  const naturalFeaturesList = ["Lake", "Forest", "Mountain"];
  const languagesList = ["English", "Spanish", "French"];

  const onSubmit = async (payload) => {
    try {
      const data = payload;
      reset();
      navigate("/listings");
      return onSave(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      setValue(key, defaultValues[key]);
    });
    setFormValues(defaultValues, setValue);
  }, [defaultValues, setValue]);

  const lat = watch("lat");
  const lng = watch("lng");

  console.log(lat, lng, "data from the api");

  useEffect(() => {
    if (geoLocation) {
      setValue("lat", geoLocation.lat, { shouldValidate: true });
      setValue("lng", geoLocation.lng, { shouldValidate: true });
    }
  }, [geoLocation, setValue]);
  
  const handleLocationSelect = (latlng) => {
    console.log(latlng, "latlnglatlnglatlng"); // Check if this is the correct value
    setGeoLocation(latlng); // Update geoLocation with the new selection
  };

  const handleToggle = () => {
    const newValue = !isDiscoverySite;
    setIsDiscoverySite(newValue);
    setValue("isDiscoverySite", newValue); // Update form state in react-hook-form
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            {...register("title")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.title && (
            <p className="text-sm text-red-500">{errors.title.message}</p>
          )}
        </div>

        {/* Is Discovery Site Toggle */}
        <div className="flex items-center mt-4">
          <label
            htmlFor="isDiscoverySite"
            className="block text-sm font-medium text-gray-700 mr-4"
          >
            Is Discovery Site
          </label>
          <button
            type="button"
            onClick={handleToggle}
            className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-200 focus:outline-none ${
              isDiscoverySite ? "bg-indigo-500" : "bg-gray-300"
            }`}
          >
            <span
              className={`inline-block w-4 h-4 transform bg-white rounded-full shadow-lg transition-transform duration-200 ${
                isDiscoverySite ? "translate-x-6" : "translate-x-1"
              }`}
            />
          </button>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Listing Type
          </label>
          <select
            {...register("listingType")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select Listing Type</option>
            <option value="CAMPGROUND">CAMPGROUND</option>
            <option value="CAMPER">CAMPER</option>
            <option value="CAMPSTAY">CAMPSTAY</option>
            <option value="HOTEL">HOTEL</option>
            <option value="HOMESTAY">HOMESTAY</option>
            <option value="TOUR_PACKAGE">TOUR_PACKAGE</option>
          </select>
          {errors.listingType && (
            <p className="text-sm text-red-500">{errors.listingType.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            {...register("description")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
          {errors.description && (
            <p className="text-sm text-red-500">{errors.description.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Country
          </label>
          <input
            type="text"
            {...register("country")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.country && (
            <p className="text-sm text-red-500">{errors.country.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            City
          </label>
          <input
            type="text"
            {...register("city")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.city && (
            <p className="text-sm text-red-500">{errors.city.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Mmunicipality (Gaupalika / Nagarpalika)
          </label>
          <input
            type="text"
            {...register("municipality")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.municipality && (
            <p className="text-sm text-red-500">
              {errors.municipality.message}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Ward number
          </label>
          <input
            type="text"
            {...register("wardNo")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.wardNo && (
            <p className="text-sm text-red-500">{errors.wardNo.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Village Name
          </label>
          <input
            type="text"
            {...register("villageName")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.villageName && (
            <p className="text-sm text-red-500">{errors.villageName.message}</p>
          )}
        </div>

        <div className="col-span-2">
          <p>
            This is the location we'll show guests on our site. Move the map to
            find the exact location of your property, then click to drop the
            pin.
          </p>
          <GeooLocationSelectorLeaflet
            onLocationSelect={handleLocationSelect}
            initialLocation={geoLocation || null} // Pass geoLocation as initialLocation
          />

          <div className="flex flex-col lg:flex-row justify-between mt-6 space-y-4 lg:space-y-0">
            {/* Readonly Lat/Lng Fields */}
            <div className="flex flex-col space-y-2">
              <label htmlFor="lat">Latitude</label>
              <input
                id="lat"
                type="text"
                value={lat || ""}
                className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="flex flex-col space-y-2">
              <label htmlFor="lng">Longitude</label>
              <input
                id="lng"
                type="text"
                value={lng || ""}
                className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Price Per night
          </label>
          <input
            type="number"
            min="0"
            step="0.01"
            {...register("pricePerNightOfTheRoom")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.pricePerNightOfTheRoom && (
            <p className="text-sm text-red-500">
              {errors.pricePerNightOfTheRoom.message}
            </p>
          )}
        </div>

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Thumbnail Image
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              {updateListing ? (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={false}
                  name="thumbnail"
                  updateAction={updateListing}
                  id={defaultValues.id}
                  onUpload={uploadListingThumbnail}
                  onDelete={deleteListingImage}
                  defaultValues={defaultValues}
                />
              ) : (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={false}
                  name="thumbnail"
                  updateAction={updateListing}
                  defaultValues={defaultValues}
                />
              )}
            </div>
          </div>
        </div>

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Gallery Images
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              {updateListing ? (
                <GalleryUploadField
                  control={control}
                  multipleUploadAllowed={true}
                  name="gallery"
                  updateAction={updateListing}
                  id={defaultValues.id}
                  onUpload={uploadListingGallery}
                  onDelete={deleteListingImage}
                />
              ) : (
                <GalleryUploadField
                  control={control}
                  multipleUploadAllowed={true}
                  name="gallery"
                  updateAction={updateListing}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500"
          >
            {isSubmitting ? <Spinner /> : "Submit"}
          </button>
        </div>
      </div>
    </form>
  );
};
