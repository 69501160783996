import { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import Dropzone from "react-dropzone";
import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { formatBytes, normalizePayloadToFormData } from "../../utils/form";

export const GalleryUploadField = ({
  control,
  multipleUploadAllowed = true,
  name = "images",
  updateAction,
  id,
  errorMessage = "",
  onUpload,
  onDelete,
}) => {
  const [refreshKey, setRefreshKey] = useState(0);

  const {
    fields: galleryImages,
    append: appendGalleryImages,
    remove: removeGalleryImages,
    replace: replaceImage,
  } = useFieldArray({
    control,
    name,
  });

  const handleAppendImages = async (files) => {
    try {
      if (updateAction) {
        const galleryFormData = normalizePayloadToFormData({
          images: files,
        });
        await onUpload(galleryFormData, id);

        setRefreshKey((prevKey) => prevKey + 1);
      }

      if (multipleUploadAllowed) {
        files.forEach((file) => {
          file.preview = URL.createObjectURL(file); // Generate preview URL
          appendGalleryImages(file);
        });
      } else {
        const singleFile = files[0];
        singleFile.preview = URL.createObjectURL(singleFile); // Generate preview URL
        replaceImage(singleFile);
      }
    } catch (err) {
      console.error("Error uploading images:", err);
    }
  };

  const handleRemoveImages = async (imageName, link, index) => {
    try {
      if (link) {
        await onDelete(id, { imageName });
      }
      const fileToRemove = galleryImages[index];
      if (fileToRemove?.preview) {
        URL.revokeObjectURL(fileToRemove.preview); // Revoke object URL
      }
      removeGalleryImages(index);
    } catch (err) {
      console.error("Error removing image:", err);
    }
  };

  // Cleanup URLs when component unmounts
  useEffect(() => {
    return () => {
      galleryImages.forEach((file) => {
        if (file?.preview) {
          URL.revokeObjectURL(file.preview);
        }
      });
    };
  }, [galleryImages]);

  return (
    <div>
      <Dropzone
        onDrop={(acceptedFiles) => handleAppendImages(acceptedFiles)}
        multiple={multipleUploadAllowed}
        accept={{
          "image/*": [
            ".png",
            ".gif",
            ".jpeg",
            ".jpg",
            ".webp",
            ".bmp",
            ".tiff",
          ],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              <p className="flex flex-col items-center px-4 py-12 text-center bg-white border border-gray-300 border-dashed rounded-md cursor-pointer hover:border-gray-400">
                <span className="mb-4 text-3xl">
                  <PhotoIcon height={24} />
                </span>

                <span className="text-sm font-light">
                  Upload files or drag and drop
                </span>
              </p>
            </div>
            {errorMessage ? (
              <p className="mt-2 text-xs text-red-500">{errorMessage}</p>
            ) : null}
          </section>
        )}
      </Dropzone>

      <ul className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
        {galleryImages.map((file, index) => {
          const fileUrl = file?.preview || file?.link;

          return (
            <li
              key={`post-image-${file.id || index}`}
              className="relative group"
            >
              <img
                src={fileUrl}
                alt={file?.originalName || file?.path || "Uploaded file"}
                className="w-full h-32 object-cover rounded-md"
              />
              <button
                className="absolute top-2 right-2 p-1 bg-gray-800 bg-opacity-75 rounded-full text-white hover:bg-opacity-100"
                onClick={() =>
                  updateAction
                    ? handleRemoveImages(file.originalName, file?.link, index)
                    : removeGalleryImages(index)
                }
                type="button"
              >
                <XMarkIcon height={20} />
              </button>
              <p className="mt-2 text-sm text-center">
                {file?.originalName || file?.path || file?.name}
                {file.size ? " - " + formatBytes(file.size) : ""}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
