import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import debounce from "lodash.debounce";

const GeooLocationSelectorLeaflet = ({ onLocationSelect, initialLocation }) => {
  const [geoLocation, setGeoLocation] = useState(null);
  const [isLocationSet, setIsLocationSet] = useState(false);
  const mapRef = useRef(null);

  const fallbackLocation = { lat: 27.701767858885457, lng: 85.3191375732422 }; // Kathmandu

  // Restricted Bounds: Define the allowed region (e.g., Nepal's approximate bounds)
  const nepalBounds = L.latLngBounds(
    L.latLng(26.347, 80.058), // Southwest corner
    L.latLng(30.422, 88.199) // Northeast corner
  );

  const detectUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = new L.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          setGeoLocation(userLocation);
          onLocationSelect(userLocation);
          setIsLocationSet(true);

          // Center the map on the detected location
          if (mapRef.current) {
            mapRef.current.setView(userLocation, 15, { animate: true });
          }
        },
        (error) => {
          console.error("Location detection failed:", error);
          alert("Unable to detect location. Using fallback location.");
          setFallbackLocation();
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
      setFallbackLocation();
    }
  };

  const setFallbackLocation = () => {
    const fallbackLatLng = new L.LatLng(
      fallbackLocation.lat,
      fallbackLocation.lng
    );
    setGeoLocation(fallbackLatLng);
    onLocationSelect(fallbackLatLng);
    setIsLocationSet(true);
  };

  useEffect(() => {
    if (!geoLocation && !isLocationSet) {
      if (initialLocation?.lat && initialLocation?.lng) {
        setGeoLocation(initialLocation);
        onLocationSelect(initialLocation);
        setIsLocationSet(true);
      } else {
        detectUserLocation();
      }
    }
  }, [geoLocation, initialLocation, onLocationSelect, isLocationSet]);

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    const newLocation = new L.LatLng(lat, lng);
    setGeoLocation(newLocation);
    onLocationSelect(newLocation);
  };

  const customIcon = L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
    iconRetinaUrl:
      "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
    shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -32],
  });

  // Debounced handler for map movements
  const debouncedMapMoveHandler = debounce((newCenter) => {
    setGeoLocation(new L.LatLng(newCenter.lat, newCenter.lng));
    onLocationSelect(new L.LatLng(newCenter.lat, newCenter.lng));
  }, 500);

  const handleMapMoveEnd = () => {
    const map = mapRef.current;
    if (map) {
      const center = map.getCenter();
      debouncedMapMoveHandler(center);
    }
  };

  if (!geoLocation) {
    return (
      <div className="flex flex-col items-center justify-center h-[600px] bg-gray-200">
        <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-500 border-t-transparent"></div>
        <p className="mt-4 text-gray-500">Loading map...</p>
        <button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={detectUserLocation}
        >
          Retry Detecting Location
        </button>
      </div>
    );
  }

  return (
    <div>
      <MapContainer
        center={[geoLocation.lat, geoLocation.lng]}
        zoom={15}
        style={{ height: "400px", width: "100%" }}
        ref={(map) => {
          if (map) mapRef.current = map;
        }}
        minZoom={6} // Set minimum zoom level
        maxZoom={18} // Set maximum zoom level
        maxBounds={nepalBounds} // Restrict bounds to Nepal
        onMoveEnd={handleMapMoveEnd} // Handle map movement
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer name="OpenStreetMap" checked>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; OpenStreetMap contributors"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Esri Imagery">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution="&copy; Esri contributors"
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <MapClickHandler onClick={handleMapClick} />
        {geoLocation && (
          <Marker
            position={[geoLocation.lat, geoLocation.lng]}
            icon={customIcon}
          >
            <Popup>
              Selected Location <br />
              Lat: {geoLocation.lat.toFixed(6)}, Lng:{" "}
              {geoLocation.lng.toFixed(6)}
            </Popup>
          </Marker>
        )}

        {/* Detect My Location Button */}
        <DetectLocationButton detectUserLocation={detectUserLocation} />
      </MapContainer>
    </div>
  );
};

// Custom "Detect My Location" Button
const DetectLocationButton = ({ detectUserLocation }) => {
  const map = useMapEvents({});

  useEffect(() => {
    const controlDiv = L.control({ position: "bottomright" });
    controlDiv.onAdd = () => {
      const div = L.DomUtil.create("div", "leaflet-bar leaflet-control");
      div.innerHTML = `
        <button 
          style="
            background-color: white;
            border: none;
            border-radius: 4px;
            padding: 8px 12px;
            cursor: pointer;
            font-size: 14px;
            box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
          "
          title="Detect My Location"
        >
          📍 Detect My Location
        </button>`;
      div.onclick = detectUserLocation;
      return div;
    };
    controlDiv.addTo(map);

    return () => {
      map.removeControl(controlDiv);
    };
  }, [map, detectUserLocation]);

  return null;
};

const MapClickHandler = ({ onClick }) => {
  useMapEvents({
    click: onClick,
  });

  return null;
};

export default GeooLocationSelectorLeaflet;
